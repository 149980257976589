<script setup lang="ts">
  import { useContentStore } from '../../stores/basic'

  const { getNotFound } = useContentStore()

  const pageData = getNotFound()
  const data = pageData?.value?.data?.teasers?.data?.[0]
</script>

<template>
  <div class="bp-main-grid">
    <h1 class="main-content text-center">HTTP 500</h1>
    <p class="main-content text-center">Internal server error</p>
  </div>
</template>
